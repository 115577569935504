






























































import Vue from 'vue'
import { qrServices } from '@/services/qr.services';
import { ResponseDetailQR } from '@/models/interface/qr.interface';
export default Vue.extend({
  data () {
    return {
      form: this.$form.createForm(this, { name: "detailQr" }),
      data: null as ResponseDetailQR | null,
      formRules: {
        merk: {
          label: "Merk",
          name: "merk",
          placeholder: "Input Merk",
          decorator: ["merk"],
        },
        type: {
          label: "Type",
          name: "type",
          placeholder: "Input Type",
          decorator: ["type"],
        },
        snAsli: {
          label: "SN Asli",
          name: "snAsli",
          placeholder: "Input SN Asli",
          decorator: ["snAsli"],
        },
        kodeUnit: {
          label: "Kode Unit",
          name: "kodeUnit",
          placeholder: "Input Kode Unit",
          decorator: ["kodeUnit"],
        },
        kapasitas: {
          label: "Kapasitas",
          name: "kapasitas",
          placeholder: "Input Kapasitas",
          decorator: ["kapasitas"],
        },
      }
    }
  },
  created () {
    const id = this.$route.params.id;
    // let params = {} as RequestQueryParamsModel
    qrServices
      .getDetailQR(id)
      .then(data => {
        this.data = data
        setTimeout(() => {
          this.form.setFieldsValue({
            merk: data.brand ? data.brand : '-',
            type: data.type ? data.type : '-',
            snAsli: data.serialNumber ? data.serialNumber : '-',
            kodeUnit: data.unitCode ? data.unitCode : '-',
            kapasitas: data.capacity ? data.capacity : '-'
          })
        }, 500);
        // merk, type, SN asli, kode unit, kapasitas,
      })
      .catch((error) => {
        if(error.status === 404) {
          qrServices
            .getDetailQrAssetAddition(id)
            .then((dataDetailAssetAddition) => {
              this.data = dataDetailAssetAddition
              setTimeout(() => {
                this.form.setFieldsValue({
                  merk: dataDetailAssetAddition.brand ? dataDetailAssetAddition.brand : '-',
                  type: dataDetailAssetAddition.type ? dataDetailAssetAddition.type : '-',
                  snAsli: dataDetailAssetAddition.serialNumber ? dataDetailAssetAddition.serialNumber : '-',
                  kodeUnit: dataDetailAssetAddition.unitCode ? dataDetailAssetAddition.unitCode : '-',
                  kapasitas: dataDetailAssetAddition.capacity ? dataDetailAssetAddition.capacity : '-'
                })
              }, 500);
            })
            .catch(() => {
              this.data = null
            })
        } else {
          this.data = null
        }
      })
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      }
    },
  }
})
